.hotelRoomCardContainer-ALL {
  display: flex;
  flex-direction: column;
  margin: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  border: 1px solid #33cccc;
  overflow: hidden;
  transition: background-color 0.2s, transform 0.2s;
  width: 350px;
  font-family:'Jomolhari';
  position: relative;

}
.hotelRoomCardContainer1-ALL {
  display: flex;
  flex-direction: column;
  position: relative;
}
/* --------------------------------------------------- */
.bed-info-container-ALL {
  display: flex;
  justify-content: flex-end; 
  align-items: center; 
  gap: 10px;
}

.bed-info-item-ALL {
  display: flex;
  align-items: center;
}

.bed-info-item-ALL img {
  width: 24px; 
  height: auto;
  margin-right: 8px;
}

.bed-info-text-ALL {
  font-size: 20px; 
  color: #333; 
  font-weight: 500;
}

.single-bed-info-ALL {
  margin-left: auto; 
}


.right-align-container-ALL {
  display: flex;
  margin-left: auto; 
}

.right-align-container-ALL .bed-info-item-ALL {
  margin: 0px;
  margin-left: auto; 
}
/*--------------------------------------------------------*/

.hotelRoomCardContainer-ALL a {
  width: 100%;
  display: block; 
}
.hotelRoomCardContainer-ALL:hover{
 
  box-shadow: 0 4px 1rem 4px #ffffff;
  transform: translateY(6px);
}

.hotelRoomCardImage-ALL {
  width: 100%; 
  max-height: 230px;
}

.hotelRoomCardContainer-ALL a img{
  width: 100%;
}
.hotelRoomCardDetails-ALL {
  flex: 1; 
  padding: 10px;
  font-family: "Jomolhari", serif;
}

.hotelRoomCardTitle-ALL {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  font-family: "lato";
  text-align: center;
  justify-content: center;
}

.hotelRoomCardTitleTextFirst-ALL{
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  border-bottom: 1px solid #B5D9D4;
  padding-bottom: 10px;
  margin-bottom: 4px;
  width: 100%;
  font-family: "Lato", serif;
}

.hotelRoomCardInfoRow-ALL {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
  border-top: 1px solid #B5D9D4; 
  margin-bottom: 10px; 
  padding-top: 10px;
}

.hotelRoomCardPriceText-ALL {
  font-size: 16px;
  font-weight: 600;
  font-family: "lato", serif;
}

.hotelRoomCard-MainRow-ALL{
  border-bottom: 2px solid rgba(5, 5, 5, 0.068); 
  margin-top: 0px;
}
.hotelRoomCardIconCol-ALL {
  display: flex;
  align-items: center;
}

.hotelRoomCardIcon-ALL {
  width: 24px; 
  height: 24px; 
  margin-right: 5px;
}

.hotelRoomCardIconText-ALL {
  font-size: 20px;
  color: #333;
  font-weight: 500;
}
.hotelRoomCardTopBar-ALL {
  background-color: rgba(0, 0, 0, 0.45);
  width: 100%;
  position: absolute;
  bottom: -4px; 
  left: 0;
  padding: 8px 10px;
  color: #ffffff;
  font-size: 15px;
  font-family: "lato", serif;
  font-weight: 600;
}

.hotelRoomViewMoreLink-ALL {
  cursor: pointer;
  color: #33cccc; 
  text-decoration:none ; 
  margin-top: 5px;
  text-align: center;
  font-family:'lato' ;
}
.hotelRoomViewMoreLink-ALL:hover {
  color: blue;
  text-decoration: underline; 
}

.cardPriceText2-ALL{
  float: right;
  font-family: "lato", serif;
  font-size: 16px;
  font-weight: 600;
}




@media only screen and (max-width: 768px) {
  .hotelRoomCardContainer-ALL {
    width: 360px;
    justify-content: center;
    margin-left: auto !important;
    margin-right: auto !important;
    left: auto;
    right: auto;
  
    
  }

  .hotelRoomCardTopBar-ALL {
    padding-right: 10px; 
    padding-left: 10px; 
    width: 100%; 
    position: absolute; 
    top: auto; 
    margin-bottom: 10px; 
  }

}

