.hotelRoomCardContainer {
  display: flex;
  flex-direction: column;
  margin: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  border: 1px solid #33cccc;
  overflow: hidden;
  transition: background-color 0.2s, transform 0.2s;
  width: 350px;
  font-family:'Jomolhari';
  position: relative;
  height: 450px;

}
.hotelRoomCardContainer1 {
  display: flex;
  flex-direction: column;
  position: relative;
}
/* --------------------------------------------------- */
.bed-info-container {
  display: flex;
  justify-content: flex-end; /* Aligns items to the right of the container */
  align-items: center; /* Centers items vertically */
  gap: 10px;/* Align items to the start of the container */
}

.bed-info-item {
  display: flex;
  align-items: center; /* Adds space between bed items */
}

.bed-info-item img {
  width: 24px; /* Adjust size of bed icons as needed */
  height: auto;
  margin-right: 8px; /* Adds space between icon and text */
}

.bed-info-text {
  font-size: 20px; /* Adjust font size as needed */
  color: #333; 
  font-weight: 500;
}

.single-bed-info {
  margin-left: auto; /* Pushes the single bed info to the right */
}

/* Add this to ensure all icons are aligned to the right */
.right-align-container {
  display: flex;
  margin-left: auto; /* Aligns items to the end of the container */
}

.right-align-container .bed-info-item {
  margin: 0px;
  margin-left: auto; /* Removes margin so that items align closely */
}
/*--------------------------------------------------------*/

.hotelRoomCardContainer a {
  width: 100%;
  display: block; /* Ensures the link takes up full width */
}
.hotelRoomCardContainer:hover{
  /* transform: scale(1.01); */
  box-shadow: 0 4px 1rem -4px #33cccc;
  transform: translateY(6px);
}

.hotelRoomCardImage {
  width: 100%; /* Ensure image fits the container */
  height: 250px;
}

.hotelRoomCardContainer a img{
  width: 100%; /* Example property */
}
.hotelRoomCardDetails {
  flex: 1; /* Take up 50% of the height */
  padding: 10px;
  font-family: "Jomolhari", serif;
}

.hotelRoomCardTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  font-family: "lato";
  text-align: center;
  justify-content: center;
}

.hotelRoomCardTitleTextFirst{
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically if needed */
  border-bottom: 1px solid #B5D9D4;
  padding-bottom: 10px;
  margin-bottom: 4px;
  width: 100%;
  font-family: "Lato", serif;
}

.hotelRoomCardInfoRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
  border-top: 1px solid #B5D9D4; /* Add top border */
  margin-bottom: 10px; /* Adjust spacing as needed */
  padding-top: 10px;
}

.hotelRoomCardPriceText {
  font-size: 16px;
  font-weight: 600;
  font-family: "lato", serif;
}

.hotelRoomCard-MainRow{
  border-bottom: 2px solid rgba(5, 5, 5, 0.068); 
  margin-top: 0px;
}
.hotelRoomCardIconCol {
  display: flex;
  align-items: center;
}

.hotelRoomCardIcon {
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Adjust the size as needed */
  margin-right: 5px; /* Space between icon and text */
}

.hotelRoomCardIconText {
  font-size: 20px;
  color: #333;
  font-weight: 500;
}
.hotelRoomCardTopBar {
  background-color: rgba(0, 0, 0, 0.45);
  width: 100%;
  position: absolute;
  bottom: -8px; /* Position it at the bottom of the image */
  left: 0;
  padding: 8px 10px;
  color: #ffffff;
  font-size: 15px;
  font-family: "lato", serif;
  font-weight: 600;
}

.hotelRoomViewMoreLink {
  cursor: pointer;
  color: #33cccc; 
  text-decoration:none ; 
  margin-top: 5px;
  text-align: center;
  font-family:'lato' ;
}
.hotelRoomViewMoreLink:hover {
  color: blue;
  text-decoration: underline; 
}

.cardPriceText2{
  float: right;
  font-family: "lato", serif;
  font-size: 16px;
  font-weight: 600;
}




@media only screen and (max-width: 768px) {
  .hotelRoomCardContainer {
    width: 360px;
    justify-content: center;
    margin-left: auto !important;
    margin-right: auto !important;
    left: auto;
    right: auto;
  
    
  }

  .hotelRoomCardTopBar {
    padding-right: 10px; 
    padding-left: 10px; 
    width: 100%; 
    position: absolute; 
    top: auto; 
    margin-bottom: 10px; 
  }

}

