@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.header-nav-bar-right .ant-menu-overflow{
  height: 100%;
  width: 100%;
}
.header-nav-bar-right{
  display: flex;
  align-items: center;

}
.header-nav-bar-left .ant-menu-overflow,
.header-nav-bar-right .ant-menu-overflow,
.update-header-main-logo,
.main-header-icon,
.header-update
{
  background-color: rgba(0, 0, 0,0.7);
}
.header-nav-bar-left .ant-menu-overflow{
  height: 100%;
  width: 100%;
}
.header-update-container{
  display: flex;
  height: 50px;
}
.mobile-toggle.ant-btn-primary{
 background-color: #669399;
}
.header-font{
  font-family: "lato", serif;
}

.header-update{
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight:400;
  border-bottom: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: fixed;
 z-index: 999;
}
.header-nav-bar-left {
  width: 100%;
}
.header-button{
  border: 1px solid #0051ff;
  width: 100px;
  background-color: transparent;
  height: 90%;
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  border-radius: 2px;
  margin-top: 2.5%;
}
.header-button:hover{
  background-color: #ffffff;
}
.ant-menu-horizontal {
  border-bottom: none; /* Remove the bottom border */
}
.header-update-toggle-button {
  display: none;
}

@media only screen and (max-width: 820px) {
  .header-nav-bar-left .ant-menu-item,
  .header-nav-bar-right .ant-menu-item{
     display: none;
     
  }
  .update-header-main-logo{
    background-color: rgba(0, 0, 0,0);
  }
  .header-nav-bar-left .ant-menu-item a,
.header-nav-bar-right .ant-menu-item a,
.update-header-drawer .ant-menu-item a {
  color: rgb(255, 255, 255) !important;
}
.header-nav-bar-left .ant-menu-item,
.header-nav-bar-right .ant-menu-item,
.update-header-drawer .ant-menu-item {
  color: rgb(255, 255, 255) !important;
  
}
  
  .header-update-toggle-button {
    display: flex; /* Display toggle button on screens smaller than 768px */
   
    margin: 10px;
    float: right;
   margin-right: 10px;
  }


  .update-header-drawer{
    width: 70% !important;
    background-color: black !important;
  }
  .update-header-drawer1,
  .update-header-drawer2{
    background-color: black !important;
  }
  .header-update{
    position: relative;
    z-index: 2;
  }

  .drawer-close-btn {
    position: absolute;
    right: 0;
    margin-right: 37% !important;
    z-index: 99999999;

  }
  .drawer-close-btn{
    margin-right: 30px;
    margin-top: 10px;
  }
  .update-header-drawer .ant-menu-item-selected {
    background-color: #669399 !important; /* Change to your desired background color */
    color: white !important; /* Change to your desired text color */
  }

}
/* HeaderUpdate.css */
.custom-dropdown-menu {
  width: 100px; /* Adjust the width as needed */
  
}

.custom-dropdown-item {
  padding: 10px 20px; /* Adjust padding for better spacing */
}
.update-header-logo{
  margin-left: 10px;
  padding: 5px;
  margin-right: 20px;
  width: 60px;
}
/* manu color */
.header-nav-bar-left .ant-menu-item,
.header-nav-bar-right .ant-menu-item,
.update-header-drawer .ant-menu-item {
  color: white;
  
}

.header-nav-bar-left .ant-menu-item a,
.header-nav-bar-right .ant-menu-item a,
.update-header-drawer .ant-menu-item a {
  color: white ;

}
.header-nav-bar-left .ant-menu-item a:hover,
.update-header-drawer .ant-menu-item a:hover {
  color: #669399 !important;
}

/* icons */
.main-header-icon {
  display: flex;
  height: 100%;

}

.header-icons{
  border-left: 0.5px solid rgb(255, 255, 255 ,0.5);
  border-width: 0.1px;
  margin-top: auto;
  margin-bottom: auto;
  
 
}

.header-icons a{
  font-size: 15px;
  color: white;
  font-weight: 200;
  z-index: 999999;
  padding: 10px;
}