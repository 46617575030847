@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap");

.BasicRoom-containor {
  max-width: 100%;
  margin: 0 auto;
  font-family: "Cinzel", serif;
}
.BasicRoom-TextWrapper {
  padding: 20px;
}

.BasicRoom-aboutH1 {
  text-align: center;
  font-size: 50px;
  font-weight: bold;
}

.BsicRoomMainDiv {
  width: 100%;
  align-items: center;
}
.BasicRoom-row {
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
  justify-content: center;
  align-items: center;
}
.BasicRoom-col {
  display: flex;
  flex-direction: row;
  gap: auto;
  justify-content: left;
}
.basicroom-card{
  margin-top:30px ;
}

@media (max-width: 768px) {
  .BasicRoom-containor {
    overflow-x: hidden;
    /* margin-left: 15px; */
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .BasicRoom-aboutH1 {
    text-align: center;
    font-size: 30px;
    margin-left: -35px;
  }
  .BsicRoomMainDiv {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}



