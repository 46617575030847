@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.WebsiteSupport {
  width: 95%;
  background-color: #659399;
  height: 500px;
  background-image: url("../../assets/images/Restaurant1.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin-top: 10px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}
.WebsiteSupport-card {
  padding-top: 10%;
}
.WebsiteSupport-title {
  text-align: center;
  font-size: 40px;
  color: rgb(0, 0, 0);
  font-family: "Cinzel", serif;
  font-weight: 500;
  padding: 35px;
}
.WebsiteSupport-containor {
  display: flex;
}

.WebsiteSupport-content {
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  width: 70%;
  border: 1px solid #659399;
  border-radius: 10px;
  margin-bottom: 20px;
}
.WebsiteSupport-sub-content p,
.WebsiteSupport-sub-title h1 {
  text-align: justify;
}
.WebsiteSupport-sub-title {
  line-height: 0px;
  margin-top: 30px;
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-family: "Cinzel", serif;
  font-size: x-large;
  margin-left: 30px;
  text-align: justify;
  margin-right: 10px;
}
.WebsiteSupport-sub-titlesub {
  color: black;
  opacity: 1;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-right: 40px;
  font-size: medium;
  font-family: "lato", sans-serif;
  font-style: italic;
}

.WebsiteSupport-sub-p {
  margin-top: 20px;
  margin-bottom: 20px;
}
.WebsiteSupport-sub-content {
  color: rgba(51, 49, 49, 0.767);
  opacity: 1;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  margin-left: 30px;
  text-align: left;
  margin-right: 40px;
  font-size: medium;
  font-family: "lato", sans-serif;
  margin-bottom: 20px;
}
.WebsiteSupport-sub-content a {
  cursor: pointer;
  text-decoration: underline;
  color: rgba(51, 49, 49, 0.767);
  opacity: 1;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  font-family: "lato", sans-serif;
  color: #659399;
}
@media screen and (max-width: 768px) {
  .WebsiteSupport {
    width: 100%;
    height: 600px;
    background-image: url("../../assets/images/image_gellery/image6.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .WebsiteSupport-sitecard {
    margin-top: 25px;
  }
  .WebsiteSupport-title {
    text-align: center;
    font-size: 50px;
    color: rgb(0, 0, 0);
    padding: 5px;
  }

  .WebsiteSupport-content {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .WebsiteSupport-containor {
    width: 95%;
    padding: 5px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
  }
  .WebsiteSupport-sub-title {
    margin-top: 20px;
    font-size: 18px;
    width: 80%;
    line-height: 1;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .WebsiteSupport-sub-title {
    line-height: 1;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
  .WebsiteSupport-sub-title {
    line-height: 1;
  }
}
