.ant-picker{

}
.custom-datepicker.ant-picker-outlined{
  border: none;
}
.custom-datepicker.ant-picker .ant-picker-suffix{
  font-size: clamp(0.5rem, 1.5vw, 2rem) !important;

}
.custom-datepicker.ant-picker .ant-picker-input >input{
  font-weight: bold;
  font-size: clamp(0.5rem, 1.1vw, 2rem) !important;

}
.custom-datepicker.ant-picker .ant-picker-input{
  width: 100%;
  align-content: space-between;
}
.custom-datepicker .anticon{
  color: #669399;
}
.ant-picker .ant-picker-clear {}
.card .ant-row {

}
.btnPrimary-check:hover {
  background-color: #000000 !important;
  color: rgb(255, 255, 255) !important; /* Optional: Change the text color to white for better contrast */
}
.card {
  width: 75%;
  /* height: 18vh;  */
  /* max-height: 250px;
  min-height: 200px; */
  border: 1px solid #ffffff;
  border-radius: 20px;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
  color: black;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
.card-body{
  padding: 6px;
}
.card-main-row{
  width: 100%;
  height: 95%;
}
.containerCol {
width: 20%;
border-right: 1px solid rgba(0, 0, 0, 0.404);
padding: 10px;
text-align: center;
line-height: 30%;
/* border-spacing:80px; */



}
.divider {
  background: #669399;
}
.divider4{
  display: none;
}

.card-titleH1 {
  color: rgb(0, 0, 0);
  margin-top: 10px;
  font-size: clamp(0.7rem, 1.2vw, 2rem);
  font-family:'Times New Roman', Times, serif;
}

.card-titleH3 {
  color: rgba(71, 54, 54, 0.5);
  margin-top: 10px;
  font-size: clamp(0.5rem, 1vw, 2rem);
  font-family: "lato", serif;
}

.buttonCol{
  border-right: none;
 
}


.btnPrimary-check {
  background-color: #669399;
  border-radius: 8px !important;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "lato", serif;
  font-size: clamp(0.3rem, 0.8vw, 1.5rem) !important;
  border-radius: 25px;
  width: 80% !important;
  padding: 10% !important;
}
.btnPrimary-check p{
  
}
.ant-space {
  display: flex;
  align-items: center;
  column-gap: 0 !important;
}

.ant-input {
  text-align: center;
  font-size: clamp(0.5rem, 1.1vw, 2rem) !important;
  width:100% !important;
}
@media (min-height: 1081px) {
  .card{
    height: 18vh; 
    max-height: 250px;
  }
}

