@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.pano-image-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.pano-image-title {
    font-family: "Cinzel", serif;
    width: 80%;
    color: black;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.pano-image-sub {
    background-color: #fff;
}

.pano-image-image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.pano-image-image-container {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    min-width: 300px; /* Fixed width for desktop view */
    min-height: 400px; /* Fixed height for desktop view */
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.pano-second-image-style {
    height: 440px;
    margin-top: -20px;
}

.pano-image-image-container img {
    width: 300px; /* Fixed width for desktop view */
    height: 400px; /* Fixed height for desktop view */
    object-fit: cover;
    border-radius: 20px;
}

.pano-image-image-container:hover {
    transform: scale(1.05);
}

.pano-image-all-button {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
}

.pano-image-nav-btn-nxt,
.pano-image-nav-btn-pre {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 24px;
    color: #333;
    transition: color 0.3s ease;
}

.pano-image-nav-btn:hover {
    color: #555;
}

.pano-image-modal {
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.808);
    padding: 30px 0px 5px 0px;
    border-radius: 10px;
    z-index: 1000;
    height: 500px;
    width: 800px;
}

.pano-image-modal iframe {
    display: block;
    margin: 0 auto;
    object-fit: cover;
    width: 790px;
    height: 500px;
    border-radius: 10px;
}

.pano-image-close-btn {
    position: absolute;
    top: -6.5px;
    right: -2px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 34px;
    color: #fff;
    transition: color 0.3s ease;
}

.pano-image-close-btn:hover {
    color: #ccc;
}

.pano-image-main2 {
    display: flex;
    align-items: center;
}

.pano-image-inactive-btn {
    opacity: 0.3;
}

.pano-image-title {
    color: black;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.pano-image-discription {
    color: black;
    margin-top: -10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: larger;
    font-family: "lato", sans-serif;
    width: 80%;
}

.pano-image-discription h2 {
    color: black;
    margin-top: 5px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.pano-image-discription {
    margin-top: -30px;
    line-height: 30px;
    margin-bottom: 25px;
}

.pano-image-nav-btn-nxt,
.pano-image-nav-btn-pre {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.pano-image-nav-btn-nxt:hover,
.pano-image-nav-btn-pre:hover {
    transform: scale(1.5);
}

.pano-image-nav-btn-nxt:hover .anticon svg,
.pano-image-nav-btn-pre:hover .anticon svg {
    color: rgb(0, 238, 255);
}

.pano-image-title-line {
    border: none;
    height: 1px;
    background-color: #33cccc;
    width: 70%;
}

@media screen and (max-width: 820px) {
    .pano-image-image-gallery {
        flex-wrap: nowrap;
        justify-content: center;
        width: 300px;
        height: 400px;
    }

    .pano-image-image-container {
        width: 100%; /* Full width for mobile view */
        height: auto; /* Auto height for mobile view */
    }

    .pano-image-image-container img {
        width: 100%; /* Full width for mobile view */
        border-radius: 0; /* Remove border radius for mobile view */
    }

    .pano-image-main2 {
        flex-direction: row; /* Display arrows horizontally on mobile view */
    }

    .pano-image-nav-btn-pre,
    .pano-image-nav-btn-nxt {
        display: block; /* Show navigation buttons */
        margin: 10px; /* Margin for navigation buttons */
    }

    .pano-image-nav-btn-pre {
        position: absolute;
        left: 10px; /* Position left */
    }

    .pano-image-nav-btn-nxt {
        position: absolute;
        right: 10px; /* Position right */
    }

    .pano-image-modal {
        height: 400px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .pano-image-modal iframe {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }

    .pano-second-image-style {
        height: auto;
        margin-top: 0;
    }
}
