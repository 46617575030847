.container-pop {
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;
  }
  
  .review-categories-pop {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  .review-text-pop1{
    color: #3b9f63;
     margin-left: 10px;
     background: none;
     border: none;
     text-decoration: underline;
  }
  
  .categories-pop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .category-pop {
    margin-bottom: 10px;
  }
  
  .category-name-pop {
    margin-bottom: -15px;
    display: flex;
    align-items: center;
    font-family: 'leto';
  }
  
  .down-arrow-pop {
    margin-left: 5px;
    color: #618e95;
  }
  
  .category-score-pop {
    font-size: 14px;
    margin-bottom: 5px;
    margin-left: 150px;
    font-weight: 600;
  }
  
  .topics-pop {
    margin: 20px 0;
  }
  
  .topics-pop .ant-tag {
    margin-bottom: 10px;
  }
  
  .progress-container-pop {
    position: relative;
    height: 20px;
    margin-top: 15px;
  }
  
  .guest-reviews-pop {
    display: flex;
    /* align-items: center; */
    font-family: 'lato';
  }
  
  .details-pop {
    margin-left: 10px;
  }
  
  .review-text-pop {
    font-weight: bold;
  }
  
  .read-reviews-link-pop {
    margin-left: 10px;
    color: #0057b8;
    text-decoration: none;
  }
  
  .read-reviews-link-pop:hover {
    text-decoration: underline;
  }
  
  .button-head-pop {
    font-size: 15px;
  }
  
  .topic-buttons-pop {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .topic-buttons-pop .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border: 1px solid gray;
    height: 30px;
    font-family: 'leto';
  }
  
  .filter-options,
  .buttons-container,
  .search-bar-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
  
  }
  
  .filter-options select {
    flex: 1;
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'leto';
  }
  
  .filter-options label {
    font-weight: 500;
    margin-right: 5px;
    font-family: 'leto';
  }
  
  .search-bar {
    flex: 1;
    height: 30px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 5px;
  }
  
  .review-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /*horizontal line top*/
  .review-card {
    display: flex;
    flex-basis: calc(30% - 20px);
    border-radius: 6px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    background-color: #020202;
    margin-top: 10px;
  }
  
  .review-content-pop {
    flex: 1;
  }
  
  .guest-reviews {
    margin-top: 20px;
  }
  
  .review-pop {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .review-info {
    width: 80%;
  }
  
  .review-actions-pop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .review-pop p {
    font-size: 10px;
  }
  
  .review-actions-pop button {
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #f4f4f5;
    color:dimgrey ;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .rev-room-type-pop{
    color: dimgrey;
  }
  .review-actions-pop .button-row-pop {
    display: flex;
    gap: 10px;
  }
  
  .review-actions-pop button:hover {
    background-color: #0056b3;
  }
  
  .rate-pop {
    margin-left: 350px;
    margin-top: -45px;
  }
  
  .guest-reviews-container {
    padding: 0px;
    font-family: Arial, sans-serif;
  }
  
  .sort-reviews-pop {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 25px;
    margin-left: 60px;
  }
  
  .sort-reviews-pop span {
    margin-right: 10px;
  }
  
  .review-header-pop {
    display: flex;
    align-items: center;
  }
  
  .reviewer-name-pop {
    font-weight: bold;
    margin-right: 5px;
    font-size: 16px;
  }
  
  .reviewer-country-pop {
    color: gray;
    font-size: 14px;
  }
  
  .review-details-pop p {
    margin: 0;
  
  }
  
  .pop-content {
    border: 1px;
  }
  
  .review-meta-pop {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  
  .review-date-pop {
    margin-right: 10px;
    color: rgb(0, 0, 0);
  }
  
  .reviewers-choice {
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 0.9em;
    color: gold;
  }
  
  .review-content h3 {
    margin-top: 0;
  }
  
  .review-actions {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .review-actions .ant-btn-link {
    margin-right: 10px;
  }
  
  .review-actions .ant-rate {
    margin-left: auto;
  }
  
  .ant-avatar {
    margin-right: 10px;
  }
  
  .score-pop {
    background-color: #136f63;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .write-pop {
    background-color: #618e95;
    color: #f4f4f5;
    border: none;
    border-radius: 5px;
    margin-left: 170px;
    height: 30px;
    width: 120px;
  }
  
  .write-pop:hover {
    background-color: rgb(0, 0, 0);
    color: #ffffff;
  }
  
  .close-icon {
    font-size: 20px;
    font-weight: bold;
    color: #000;
  }
  
  .review-actions {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .review-actions .ant-btn-link {
    margin-right: 10px;
  }
  
  .review-actions .ant-rate {
    margin-left: auto;
  }
  
  .clicked-text-useful {
    margin-top: 10px;
    font-size: 15px;
    margin-bottom: 10px;
    color: #1619dc;
    font-family: 'leto';
  }
  
  /*----------------------------------*/
  .review-item-pop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: #ffffff;
  }
  
  .review-meta-pop {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-right: 20px;
    width: 30%;
  }
  
  .review-content-pop {
    flex: 1;
  }
  
  .review-actions-pop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .review-header-pop {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .reviewer-name-pop {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .reviewer-country-pop {
    color: #417885;
  }
  
  .review-details-pop p {
    margin: 0;
  }
  
  .review-date-pop {
    margin-right: 10px;
    color: rgb(3, 3, 3);
  }
  
  .reviewers-choice-pop {
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 0.9em;
    color: gold;
  }
  
  .review-title-pop {
    font-size: 15px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
    font-family: 'lato';
  }
  
  .review-text-pop {
    font-size: 14px;
    font-family: 'lato';
    margin: 0;
    color:  #417885;
  }
  
  .title-top-pop {
    font-family: 'Cinzel';
  }
  .custom-modal2{
    text-align: center;
  }
  @media only screen and (min-width: 350px) and (max-width: 389px) {
    .categories-pop {
      display: grid;
      grid-template-columns: repeat(1, 350px);
      gap: 10px;
      
    }
    .container-pop{
      width:300px;
      margin-left: -40px;
    }
    .rate-pop {
      margin-left: 10px;
      margin-top: 10px;
      font-size: 16px;
    }
   .review-text-pop1{
    margin-top: 1px;
    margin-left: -5px;
    width: 30%;
   }
    .write-pop {
      margin-left: -5px;
      margin-top: 10px;
      height: 30px;
      width: 90px;
      font-size: 10px;
      
    }
  
    .sort-reviews-pop {
  
      margin-bottom: 20px;
      margin-left: 10px;
    }
  
  
    .category-score-pop {
      margin-left: 250px;
    }
  
    .filter-options label {
      font-weight: 500;
      margin-right: 15px;
    }
  
    .review-date-pop {
      width: 200px;
      margin-left: 10px;
  
    }
  
    .reviewers-choice-pop {
      width: 100px;
      margin-left: 50px;
    }
    .review-actions-pop{
      margin-left: -10px;
    }
    .review-details-pop p {
      margin: -10px;
    
    }
   
  }
  @media only screen and (min-width: 390px) and (max-width: 435px) {
    .categories-pop {
      display: grid;
      grid-template-columns: repeat(1, 350px);
      gap: 10px;
      
    }
    .container-pop{
      width:340px;
      margin-left: -40px;
    }
    .rate-pop {
      margin-left: 10px;
      margin-top: 10px;
    }
   .review-text-pop1{
    margin-top: 10px;
    margin-left: -5px;
   }
    .write-pop {
      margin-left: -10px;
      margin-top: 10px;
      height: 30px;
      width: 100px;
      font-size: 12px;
    }
  
    .sort-reviews-pop {
  
      margin-bottom: 20px;
      margin-left: 10px;
    }
    .review-details-pop p {
      margin: -10px;
    
    }
    .filter-options {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
    .filter-pop{
      float: right;
      width: 150px;
    }
  
    .category-score-pop {
      margin-left: 250px;
    }
  
    .filter-options label {
      font-weight: 500;
      margin-right: 15px;
    }
  
    .review-date-pop {
      margin-left: 0px;
  
    }
  
    .reviewers-choice-pop {
      width: 100px;
      margin-left: 50px;
    }
    .review-actions-pop{
      margin-left: -10px;
    }
    .score-pop {
     
      padding: 15px 10px;
      border-radius: 5px;
      font-size: 15px;
      font-weight: bold;
    }
   
  }
  @media only screen and (min-width: 440px) and (max-width: 769px) {
    .rate-pop {
      margin-left: 10px;
      margin-top: 10px;
    }
  }