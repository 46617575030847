@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.privacy-main {
  width: 95%;
  background-color: #33cccc;
  height: 500px;
  background-image: url("../../assets/images/Restaurant1.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin-top: 10px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}
.privacy-sitecard{
  margin-top: 25px;
}

.privacy-title {
  text-align: center;
  font-size: 40px;
  color: rgb(0, 0, 0);
  font-family: "Cinzel", serif;
  font-weight: 500;
  padding: 35px;
}
.privacy-card {
  padding-top: 10%;
}
.privacy-sub {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  border: 1px solid #33cccc;
  border-radius: 10px;
  margin-bottom: 20px;
}
.privacy-Containor {
  width: 95%;
  padding: 10px;
}
.privacy-sub-title {
  margin-top: 30px;
  color: rgb(0, 0, 0);
  font-weight: 500;
  margin-bottom: 30px;
  font-family: "Cinzel", serif;
  font-size: x-large;
}
.privacy-sub-content {
  color: rgba(51, 49, 49, 0.767);
  opacity: 1;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  margin-left: 40px;
  text-align: justify;
  margin-right: 40px;
  font-size: medium;
  font-family: "lato", sans-serif;
}
.privacy-Containor p,
.privacy-Containor h1 {
  margin-left: 30px;
  text-align: justify;
  margin-right: 10px;
}
.privacy-line {
  border: none;
  height: 1px;
  background-color: #33cccc;
  margin-left: 30px;
}
@media screen and (max-width: 768px) {
  .privacy-main {
    width: 100%;
    height: 600px;
    background-image: url("../../assets/images/image_gellery/image6.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .privacy-title {
    text-align: center;
    font-size: 50px;
    padding: 5px;
  }

  .privacy-sub {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .privacy-Containor {
    width: 95%;
    padding: 5px;

    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-left: 0;
  }

  .privacy-sub-title {
    margin-top: 20px;
    font-size: 18px;
    width: 80%;
  }

  .privacy-sub-content {
    margin-top: 10px;
  }
}
