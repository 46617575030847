
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.new-meeting-card-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;

  
  }
  /* .new-meeting-title{
    margin-bottom: 600px !important;
  } */
.new-meeting-title{
  margin-top: 45px;
}
  .new-meeting-nav-button {
    color: rgb(0, 238, 255);
    border: none;
    cursor: pointer;
    background: none;
    font-size: 25px;
    transform: none;
    transition: transform 0.3s ease; /* Add transition for smooth effect */
  }
  
  .new-meeting-nav-button:hover {
    transform: scale(1.5); /* Zoom effect: increase size by 10% */
  }
  
  
  .new-meeting-cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: transform 0.5s ease-in-out; */
    width: 80%;
    padding: 50px;
    margin-bottom: 20px;
    padding-top: 0;
  }
  
  .new-meeting-card {
    width: 300px;
    height: 400px;
    margin: 0 15px;
    position: relative;
    /* transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; */
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 1;
    border-radius: 15px;

  

  }
  
.new-meeting-active img {
 
    border-radius: 15px;
    object-fit: cover;
    height: 280px;
    width: 300px;


  }
  .new-meeting-card.new-meeting-non-active img{
    object-fit: cover;
    width: 300px;
    height: 400px;
    border-radius: 15px;
    
  }
  
  .new-meeting-card-content {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    line-height: 1px;
    font-family: "Cinzel", serif;
  }
  
  .new-meeting-card.new-meeting-active {
    transform: scale(1.17);
    opacity: 1; z-index: 8;
    background-color: rgb(255, 255, 255);
    margin-top: 50px;
    border:  solid #e9e4e4;
    margin-right: 25px;
    margin-left: 25px;
    /* box-shadow:  #33cccc;
    transform: scale(1.1); */


  }
  .new-meeting-active {

  }
  
  .new-meeting-active:hover {
    box-shadow: 0px 4px 10px #33cccc; /* Add some spread to the box-shadow */
    transform: scale(1.2);
  }

  
  .new-meeting-card.new-meeting-active .new-meeting-card-content {
    position: relative;
    color: rgb(2, 0, 0);
    opacity: 1;
    margin-bottom: 20px;
    background-color: transparent;


  }
    .new-meeting-card.new-meeting-non-active {
       
    }
  .new-meeting-card.new-meeting-non-active .new-meeting-card-content {
    color: rgb(255, 255, 255);
    opacity: 1;
    margin-bottom: 20px;
    /* background-color: transparent; */
    top: 0;
    left: 0;
    height: 400px;
    border-radius: 15px;

  }
  .new-meeting-overlay-content {
    opacity: 1;
  }
 .new-meeting-non-active .new-meeting-title{
    margin-top: 190px;
  }
  .new-meeting-card.new-meeting-non-active .new-meeting-card-content{

  }
  @media (max-width: 768px) {
    .new-meeting-cards-container {
      width: 100%;
    }
    .new-meeting-active img {
 
      width: 300px !important;

    
      }
  
    .new-meeting-card {
      display: none;
    }
  
    .new-meeting-card.new-meeting-active {
      display: block;

    }
  
    .new-meeting-card.new-meeting-active .new-meeting-card-content {
      position: static;
      background-color: transparent;
      color: black;
      opacity: 1;
    }
    
  }
  