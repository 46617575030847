.new-about {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr)); /* 4 carousels per row, each at least 200px */
  gap: 20px; /* Adjust the gap between carousels as needed */
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.new-about-main .carousel {
  position: relative;
  border-radius: 10px;
  
}

.new-about-main .carousel-image {
  width: 100%;
  height: 300px; /* Adjust the height as needed */
  object-fit: cover;
  position: relative; /* Ensure that the image acts as a reference for absolute positioning of the indicators */
  border-radius: 10px;
}

/* Custom line indicators container */
.new-about-main .carousel-indicators {
  position: absolute;
  bottom: 10px; /* Position the indicators at the bottom of the image */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  justify-content: center;
}

/* Custom line indicators */
.new-about-main .carousel-indicators .line {
  width: 20px; /* Adjust the width of each line indicator */
  height: 2px; /* Height of the line */
  background-color: white; /* Default line color */
  opacity: 0.5; /* Slightly transparent */
  transition: all 0.3s ease; /* Smooth transition */
}

/* Active line indicator */
.new-about-main .carousel-indicators .active {
  background-color: #f39c12; /* Active line color */
  opacity: 1; /* Full opacity for active line */
}

/* Responsive Design for 1366px or smaller screens */
@media (max-width: 1024px) {
  .new-about {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Flexible columns with a minimum width of 300px */
  }

  .new-about-main .carousel {
    width: 100%; /* Let the carousel take full available width in its grid cell */
    margin: 0 auto;
    position: relative;
  }
}
@media (max-width: 600px) {
    .carousel3,
    .carousel4{
      
      display: none;
    }
}