
.ant-modal {
  width: 90% !important;
}
.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  color: #639497;
  transition: color 0.3s;
}

.tab-button.active {
  font-weight: bold;
  border-bottom: 2px solid #639497;
}

.tab-button:hover {
  color: #417a8d;
}

.bathroom-type img {
  width: 10px;
  height: auto;
  margin-left: 15px;
}
.bathroom-type{
  display: flex;
  align-items: center;
  margin-bottom: 8px; 
}
.RoomInformation-section:hover {
  text-decoration: underline;
  color: #639497;
}
.RoomInformation-section::after {
  content: "";
  position: absolute;
  top: 13px;
  bottom: 13px;
  right: -10px;
  width: 1px;
  background-color: black;
  transform: translateY(-50%);
  margin-top: 15px;
}


.RoomInformation-section-All {
  border-bottom: 1px solid gray;
}

.RoomInformation-heading {
  color: #639497;
  font-weight: 500;
  text-decoration: underline
  
}
.RoomInformation-headings {
  color: #639497;
  font-weight: 500;
  margin-left: 10px;
  
}
.RoomInformation-subHeadings {
  text-transform: uppercase;
  margin-left: 10px;
}
.RoomInformation-main-container{
  display: flex;
  margin-left: 10px;
}
.RoomInformation-icon-container {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;

}
.RoomInformation-bed-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px; 
}

.RoomInformation-bed-item img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.RoomInformation-iconText {
  display: flex;
  align-items: center;
  font-size: 14px; 
}
.RoomInformation-iconText {
  margin-right: 10px;
  margin-left: 0px;

}
.RoomInformation-text{
  margin-left: 10px;
}
.time-zone{
  margin-left: 10px;
}

.RoomInformation-textP {
  max-width: 100%; 
  margin: 10 auto;  
  text-align: justify; 
  padding: 0 20px; 
}

.fs-cont{
  display: flex;
  margin-left: 10px;
}
.facilitieshidebutton1{
  display: flex;
  border: none;
  padding: 20px;
  background-color: #fffff9;
  color: #639497;
  margin: auto;

}

.facilitiesshowbutton2{
  display: flex;
  flex-direction: column;

}
.facilitieshidebutton1:hover {
  background-color: #ffffff;
  color: black;
 
}
.facilitiesshowbutton1{
  display: flex;
  border: none;
  padding: 20px;
  background-color: #ffffff;
  color: #639497;
  margin: auto;
}
.facilitiesshowbutton1:hover {
  background-color: #ffffff;
  color: black;
 
}

@media (min-width: 769px) {
  .app-container2-site{
    height: 100%;
  }
}
@media (max-width: 768px) {
   .ant-modal {
    width: 90% !important;

  }
  .site-card-room {
    position: absolute;
    display: flex !important;
    top: 400px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    z-index: 9999999999999999999999999;
}
.app-container2-site{
  position: absolute;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 9999999999999999999999999;
}
.img-care {
  overflow: hidden; 
  width: 100% !important;
}
.room-info-text-content{
  margin-top: 420px;
}
}
@media (min-width: 1250px) {
  .img-care {
    overflow: hidden; 
    width: 100% !important;
  }
  .site-card-room {
    min-width: 300px;
  }

}


/* carousel style */
.new-main {
  width: 300px;
  height: 300px !important;
  position: relative;
}

.new-custom-carousel .carousel-slide {
  width: 100%;
  height: 330px;
  overflow: hidden;
  
}

.new-custom-carousel .carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.new-custom-carousel .slick-prev,
.new-custom-carousel .slick-next {
  color: #00a2ff !important; 
  font-size: 44px !important; 
  z-index: 1;

}

.new-custom-carousel .slick-prev:hover,
.new-custom-carousel .slick-next:hover {
  color: #0400ff; 
}

.new-custom-carousel .slick-dots li button {
  background-color: #ffffff; 

}

.new-custom-carousel .slick-dots li.slick-active button {
  background-color: #1890ff; 
}

.carousel-card{
  display: flex;
  width: 100%;
  gap: 10px;
}

.new-main{
  width: 100%;
}
.new-custom-carousel{
  width: 100% !important;
}
.img-care {
  overflow: hidden;
  width: 65%;
  border-radius: 15px;
}
.site-card-room{
  width: 35%;
  min-width: 300px;;
}

.img-care img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}




/* 000000000000000 */
@media (min-width: 1280px) and(max-width: 1599px) {
  .ant-modal {
    width: 80% !important;
  }
}
@media (min-width: 1600px) {
  .ant-modal {
    width: 1500px !important;
  }
  .carousel-slide{
    height: 600px !important;
  }
  .carousel-card{
    height: 600px;
    gap: 5px;
  }
  .img-care {
    overflow: hidden; 
    border-radius: 15px !important;
    height: 600px;
  }

  .card-body-site{
  
    gap: 20px;

  }
  .check-head-site{
    font-size:30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  .current-day-site {
    font-size: 21px;
  }
  .date-input-container-site {
    height: 45px;
  }
  .button-site-div{
    margin-bottom: 10%;
  }
  .td-site{
    gap: 10%;
    display: flex;
    flex-direction: column;
  }
  .button-site{

    font-size: 1.4rem;
  }
  .price-vat-txt{
    font-size: 1.3rem;
  }

  .vat-dropdown .arrow{
    margin-left: 250px;
  }
}

@media (min-width: 769px) and (max-width: 1599px){
  .ant-modal {

  }
  .carousel-slide{
    height: 400px !important;
  }
  .carousel-card{

    height: 400px;

  }
  .img-care {
    overflow: hidden; 
    border-radius: 15px !important;
    height: 400px;
  }
}

