
.head-main {
  position: relative;
  background-image: url("../assets/images/HotelFront.webp");
  background-size: cover;
  text-align: center;
  overflow: hidden; /* Ensures overlay stays within container bounds */
  width: 100%;
  margin-top: -10px;
  /* height: 90vh; */
  height: 100% !important;
  background-position: center center; /* Focus on the middle of the image */
}

.hero-container{
  position: relative;
  margin-bottom: 150px;
  z-index: 2 !important;
}
.headerLogo {
  width: 400px;
  padding: 20px;
  filter: brightness(130%);

}

.headerLogoPosition {
  margin-top: 50px;

}



.header-slogan {
  background-color: rgb(255, 255, 255);
  width: fit-content;
  padding: 10px 40px;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 2px;
  margin-bottom: 40px;
  font-family: "Cinzel", serif;

}

/* @media screen and (width: 390px) {
  .home-sitecard {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
    margin-top: 33% !important;
    z-index: 999;
    text-align: left;
    width: 100%;
  }
} */


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Black color with 0.5 opacity */
  z-index: 1; /* Ensures the overlay is above the background but below the content */
  pointer-events: none; /* Ensures that the overlay doesn't interfere with clicking elements inside the container */
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .headerLogo {
    width: 300px; /* Reduce the size further for small devices */
  }
    .hero-container{

      margin-bottom: 50px;
    }
  }


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 375px) {
  .headerLogo {
    width: 150px; /* Further reduce the size for extra small devices */
  }
}
