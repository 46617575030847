.hotelRoomCardContainer-R1 {
    display: flex;
    flex-direction: column;
    margin: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    border: 1px solid #33cccc;
    overflow: hidden;
    transition: background-color 0.2s, transform 0.2s;
    width: 350px;
    font-family:'Jomolhari';
    position: relative;
}
    .hotelRoomCardContainer-R1:hover{
      /* transform: scale(1.01); */
      box-shadow: 0 2px 1rem -4px #33cccc;
      transform: translateY(6px);
    }

  .hotelRoomCardContainer1-R1 {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 310px;
  }
  /* --------------------------------------------------- */
  .bed-info-container-R1 {
    display: flex;
    justify-content: flex-end; 
    align-items: center; 
    gap: 10px;
  }
  
  .bed-info-item-R1 {
    display: flex;
    align-items: center; 
  }
  
  .bed-info-item-R1 img {
    width: 24px;
    height: auto;
    margin-right: 8px; 
  }
  
  .bed-info-text-R1 {
    font-size: 15px; 
    color: #333;
  }
  
  .single-bed-info-R1 {
    margin-left: auto; 
  }
  
 
  .right-align-container-R1 {
    display: flex;
    margin-left: auto; 
  }
  
  .right-align-container-R1 .bed-info-item {
    margin: 0px;
    margin-left: auto; 
  }
  /*--------------------------------------------------------*/
  
  .hotelRoomCardContainer-R1 a {
    width: 100%;
    display: block; 
  }
  .hotelRoomCardContainer-R1:hover{
    /* transform: scale(1.01); */
    box-shadow: 0 4px 1rem -4px #33cccc;
    transform: translateY(6px);
  }
  
  .hotelRoomCardImage-R1 {
    width: 100%; 
    height: 310px;
  }
  
  .hotelRoomCardContainer-R1 a img{
    width: 100%; 
  }
  .hotelRoomCardDetails-R1 {
    flex: 1;
    padding: 10px;
    font-family: "Jomolhari", serif;
  }
  
  .hotelRoomCardTitle-R1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
    font-family: "LATO", serif;
    text-align: center;
    justify-content: center;
  }
  
  .hotelRoomCardTitleTextFirst-R1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    border-bottom: 1px solid #B5D9D4;
    padding-bottom: 10px;
    margin-bottom: 4px;
    width: 100%;
    font-family: "Lato", serif;
  }
  
  .hotelRoomCardInfoRow-R1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
    border-top: 1px solid #9da7a6; 
    margin-bottom: 10px;
    padding-top: 10px;
  }
  
  .hotelRoomCardPriceText-R1 {
    font-size: 20px;
    font-weight: 400;
    font-family: "Jomolhari", serif;
    margin-left: 10px;
   
  }
  
  .hotelRoomCard-MainRow-R1{
    border-bottom: 2px solid rgba(5, 5, 5, 0.068); 
    margin-top: 0px;
  }
  .hotelRoomCardIconCol-R1 {
    display: flex;
    align-items: center;
  }
  
  .hotelRoomCardIcon-R1 {
    width: 24px; 
    height: 24px; 
    margin-right: 5px; 
  }
  
  .hotelRoomCardIconText-R1 {
    font-size: 15px; 
  }
  .hotelRoomCardTopBar-R1 {
    background-color:rgba(0, 0, 0, 0.45);
    width: 100%;
    position: absolute;
    bottom: -12px; 
    left: 0;
    padding: 8px 10px;
    color: #ffffff;
    font-size: 18px;
    font-family: "lato", serif;
    font-weight: 600;
  }
  
  .hotelRoomViewMoreLink-R1 {
    cursor: pointer;
    color: #33cccc;
    margin-top: 20px; 
    text-decoration:none ;
    font-family:'Jomolhari' ;
    font-size: 20px;
    margin-left: 60px;
  }
  .hotelRoomViewMoreLink-R1:hover {
    color: blue;
    text-decoration: underline; 
  }
  
  .cardPriceText2-R1{
    float: right;
    font-family: "Jomolhari", serif;
  }
  
  
  
  
  @media only screen and (max-width: 768px) {
    .hotelRoomCardContainer-R1 {
      width: 360px;
      justify-content: center;
      margin-left: auto !important;
      margin-right: auto !important;
      left: auto;
      right: auto;
    
      
    }
  
    .hotelRoomCardTopBar-R1 {
      padding-right: 10px; 
      padding-left: 10px; 
      width: 100%; 
      position: absolute; 
      top: auto; 
      margin-bottom: 10px; 
    }
  
  }
  
  