.hotelRoomMainDiv-R2 {
    width: 100%;
    align-items: center;
  
  
  }
  .rooms-sub-content-R2{
    color: rgba(51, 49, 49, 0.767);
    opacity: 1;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: justify;
    line-height: 1.5;
    font-family: "lato", sans-serif;
    text-align: center;
    
  }
  .hotelRoom-row-R2 {
   width: 100%;
   margin-left: auto !important;
   margin-right: auto !important;
   justify-content: center;
   align-items: center;
  }
  .hotelRoom-col-R2 {
    display: flex;
    flex-direction: row;
    gap: auto;
  
  }
  .custom-modal .ant-modal-content {
    background-color: rgba(0, 0, 0, 0.767); 
}

.custom-modal .ant-modal-header {
    background-color: #ffffff;
}

.custom-modal .ant-modal-body {
    background-color: rgba(0, 0, 0, 0.767)); 
}
.custom-modal .ant-modal-close-x {
  color: #ffffff; 
  font-size: 20px; 
}


.custom-modal .ant-modal-close-x:hover {
  color: #ff4d4f; 
}
  
  @media (max-width: 768px) {
        .hotelRoomMainDiv-R2 {
          margin-left: auto;
          margin-right: auto;
          width: 90%;
        }
        .rooms-sub-content{
          text-align: justify;
          width: 95%;
          
        }
    
    .hotelRoomMainRow-R2 {
      display: block; 
      width: 80%;
      
      
    }
    .rooms-main-contaner-R2 {
      width: 100%;
    }
  
    .hotelRoomMainRow > .ant-col {
      width: 100%; 
      margin-bottom: 20px; 
    }
  }
  .room-text-wraper-R2{
    width: 95%;
   margin-left: auto;
   margin-right: auto;
   text-align: center;
  }
  