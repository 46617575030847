.new-main{
  height: 300px;
}
.new-custom-carousel {
  width: 70%;
  height: auto; 
  overflow: hidden;

}

.new-custom-carousel .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slick-slide img {
  width: 100%;
  object-fit: cover;
  /* filter: brightness(1.2) contrast(1.0) saturate(1.5);
  transition: filter 0.3s ease; */
} 

.carousel-slide{
  width: 100%;
}