@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.FaqDetails-main {
  width: 95%;
  background-color: #659399;
  height: 500px;
  background-image: url("../../assets/images/Restaurant1.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin-top: 10px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}
.FaqDetails-title {
  text-align: center;
  font-size: 40px;
  color: rgb(0, 0, 0);
  font-family: "Cinzel", serif;
  font-weight: 500;
  padding: 35px;
}

.FaqDetails-container {
  font-size: medium;
  font-family: "lato", sans-serif;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  padding-top: 20px;
  box-sizing: border-box;
  overflow-y: auto; /* Allow vertical scrolling */
}
.FaqDetails-view-full-text {
  font-size: medium;
  font-family: "lato", sans-serif;
  background: #659399;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  padding: 10px;
  margin: 10px 0; /* Adjust as needed */
  display: block;
}
.FaqDetails-view-full-text:hover{
  background: white;
  color: #659399;
  text-decoration: underline;
}
@media (max-width: 768px) {
    .FaqDetails-main{
        width: 100%;
        height: 600px;
        background-image: url("../../assets/images/image_gellery/image6.webp");
        background-repeat: no-repeat;
        background-size: cover;
    }    
    .FaqDetails-title{
        text-align: center;
        font-size: 37px;
        /* color: white; */
        padding: 5px;
    }
}
