.hotelRoomMainDiv-ALL {
    width: 100%;
    align-items: center;
    margin-bottom: 60px;
  
  
  }
  .rooms-sub-content-ALL{
    color: rgba(51, 49, 49, 0.767);
    opacity: 1;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: justify;
    line-height: 1.5;
    font-family: "lato", sans-serif;
    text-align: center;
    
  }
  .hotelRoom-row-ALL {
   width: 90%;
   margin-left: auto !important;
   margin-right: auto !important;
   justify-content: center;
   align-items: center;
  }
  .hotelRoom-col-ALL {
    display: flex;
    flex-direction: row;
    gap: auto;
  
  }
  .location-title-ALL{
    text-align: center;
    font-size: 30px;
    color: #fbffff;
    font-weight: 600;
    padding: 5px;
    margin-bottom: 20px;
    font-family: 'Cinzel';
   
  }
  
  @media (max-width: 768px) {
        .hotelRoomMainDiv-ALL {
          margin-left: -25px;
         
          width: 115%;
        }
        .rooms-sub-content-ALL{
          text-align: justify;
          width: 95%;
          
        }
    
    .hotelRoomMainRow-ALL {
      display: block;
      width: 80%;
      
    }
    .rooms-main-contaner-ALL {
      width: 100%;
    }
  
    .hotelRoomMainRow-ALL > .ant-col {
      width: 100%; 
      margin-bottom: 20px; 
    }
  }
  .room-text-wraper-ALL{
    width: 95%;
   margin-left: auto;
   margin-right: auto;
   text-align: center;
  }
  