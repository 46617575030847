@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.signUpIn-body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}
.signUpIn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  width: 100%;
}

.signUpIn-fullRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.signUpIn-Logpicture {
  flex: 2;
  display: flex;
  justify-content: center;
}

.signUpIn-Logpicture img {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
}

.signUpIn-header {
  flex: 1;
  padding: 20px;
  margin: 50px 25px;
}

.signUpIn-text {
  font-size: 1.5rem;
  font-weight: 700;
  font-style: normal;
  color: #659399;
  font-family: "Lato", sans-serif;
}

.signUpIn-Subtext {
  margin-top: 10px;
  color: #555;
  font-size: small;
  font-family: "Lato", sans-serif;
}

.signUpIn-inputs {
  margin-top: 30px;
}

.signUpIn-input {
  margin-bottom: 10px;
  color: #d5d5d5;
}

.signUpIn-input input {
  width: 90%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #f4f5fb;
  font-family: "Lato", sans-serif;
  font-size: medium;
}
.signUpIn-passwordWrapper {
  position: relative;
}
.signUpIn-ResetpasswordWrapper {
  position: relative;
  margin-top: 15px;
}
.signUpIn-togglePassword {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.signUpIn-input input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder {
  color: #d5d5d5;
  font-family: "Lato", sans-serif;
  font-size: medium;
}

.signUpIn-SubmitContainor {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  font-style: normal;
  font-family: "Lato", sans-serif;
  font-size: large;
}
.signUpIn-SubmitContainor :hover{
  background-color: #bde9fc;
  color:#333;
}

.signUpIn-submit {
  background-color: #659399;
  color: #fff;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: medium;
  border: none;
}


.signUpIn-signUpGoogle {
  margin-top: 10px;
  color: black;
  text-align: center;
  padding: 10px;
  border: 2px solid #659399;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: medium;
}

.signUpIn-alreadyAccount {
  margin-top: 20px;
  font-size: 14px;
}

.signUpIn-alreadyAccount span {
  color: #659399;
  cursor: pointer;
}
.signUpIn-input input:focus {
  outline: none;
}
.signUpIn-alreadyAccount a {
  cursor: pointer;
  color: #659399;
}

.signUpIn-alreadyAccount a:hover {
  text-decoration: none;
  color: blue;
  cursor: pointer;
}

.signUpIn-error {
  color: red;
  font-size: 14px;
}
.signUpIn-successMessage {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}
.signUpIn-forgotPassword {
  text-align: right;
}

.signUpIn-forgotPassword a {
  text-decoration: none;
  text-transform: uppercase;
  color: #659399;
  font-size: small;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}
.signUpIn-forgotPassword :hover {
  cursor: pointer;
  text-decoration: underline;
  color: #bde9fc;
}
/* .signUpIn-successMessage a {
  color: #659399;
  text-decoration: underline;
  font-family: "Lato", sans-serif;
  font-size: medium;
} */

.signUpIn-SubmitContainor {
  font-size: small;
}
.signUpIn-successMessage {
  font-size: medium;
  font-family: "Lato", sans-serif;
}
.google-login-button {
  width: 100%;
  margin-top: 10px;
}
.haAclf {
  width: 100%;
}
@media (max-width: 500px) {
  .signUpIn-Logpicture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75vh;
    z-index: -1;
  }
  .signUpIn-Logpicture img {
    width: 100%;
    height: 75vh;
    object-fit: cover;
  }
  .signUpIn-header {
    position: relative;
    z-index: 1;
    padding: 10px;
    margin: 50px;
    background-color: white;
    width: 70%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .signUpIn-inputs {
    margin-top: 20px;
  }
  .signUpIn-input input {
    width: 80%;
    padding: 10px;
    border: none;
    background: transparent;
    font-size: small;
    border-bottom: 1px solid #f4f5fb;
  }
  .signUpIn-input input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    font-size: small;
  }
  .signUpIn-SubmitContainor {
    font-size: small;
  }
  .signUpIn-submit {
    font-size: small;
  }
  .signUpIn-signUpGoogle {
    font-size: small;
  }
  .signUpIn-successMessage {
    font-size: small;
  }
  .signUpIn-forgotPassword a {
    font-size: small;
  }
}
@media (min-width: 501) and (max-width: 768px) {
  .signUpIn-Logpicture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75vh;
    z-index: -1;
  }
  .signUpIn-Logpicture img {
    width: 100%;
    height: 75vh;
    object-fit: cover;
  }
  .signUpIn-header {
    position: relative;
    z-index: 1;
    background-color: white;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .signUpIn-inputs {
    margin-top: 20px;
  }
  .signUpIn-input input {
    width: 80%;
    padding: 10px;
    border: none;
    background: transparent;
    font-size: 20px;
    border-bottom: 1px solid #f4f5fb;
  }
  .signUpIn-input input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    font-size: small;
  }
  .signUpIn-SubmitContainor {
    font-size: small;
  }
  .signUpIn-submit {
    font-size: small;
  }
  .signUpIn-signUpGoogle {
    font-size: small;
  }
  .signUpIn-successMessage {
    font-size: small;
  }
  .signUpIn-forgotPassword a {
    font-size: small;
  }
}
/* for the other mobile devices */
@media screen and (max-width: 480px){
  .signUpIn-Logpicture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; 
    overflow: hidden;
    z-index: -1; 
  }
  .signUpIn-Logpicture img {
    width: 100%;
    height: auto;
    object-fit: cover; 
  }
}

