.total-price-p{
  margin-bottom: 0;
}


.app-container2-site{
min-width: 300px;
border: 1px solid rgba(0, 0, 0, 0.377);
height: auto;
/* padding: 2px; */
border-radius: 15px;
background-color: #f3ebeb3d;



}
.card-body-site{
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  width: 85%;
}
.check-head-site{
  font-size:20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.custom-datepicker1.ant-picker-outlined {
  width: 100%;
}
.custom-datepicker1.ant-picker .ant-picker-input {
  width: 100%;
}
.check-head-site{
  font-weight: 500;
  line-height: 0 !important;
}
.button-site-div{
  margin-top: 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.button-site{
  width: 100%;
  padding: 10px;
    background-color: #669399;
    color: white;
    border-radius: 25px;
    border: none;
}
.button-site:hover {
  background-color: #000000 !important;
  color: rgb(255, 255, 255) !important; /* Optional: Change the text color to white for better contrast */
}



/* 1111111111111111111 */


.card-body-site tr:not(:last-child) {
  border-bottom: 1px solid #ddd; 
}
.date-input-container-site .ant-picker-input input {
  width: 100%; 
  padding: 0px; 
  font-size: 15px; 
  border: 0px solid #ccc; 
  font-weight: 500; 
}
.date-input-container-site .ant-picker-input input::placeholder {
  font-style: normal;
  font-size: 10px;
  color: #a49d9d;
  font-weight: 200;
}
.current-day-site {
  font-size: 15px;
  color: #000000;
  margin-bottom: 5px;
  margin-left: 5px;
}
.last-column-site {
  border-right: none;
   /* Remove border on the right side of the last column */
}
.valueupdown-site{
  background-color: #f5f2f2;
  border-radius: 90%;
  color: #618e95;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: 600;
}


.price-site{
  float: right;
  font-weight: 500;
  margin-bottom: 30px;
}
.day-of-week{
  font-size: 12px;
  font-weight: bold;
}
/* .custom-date-picker{
  font-size: 25px;
} */


.custom-datepicker1.ant-picker .ant-picker-input > input {
  font-size: 15px;
  font-weight: bold;
}



.custom-datepicker1 .anticon {
  color: #669399;
}
/* CSS */
/* .react-datepicker-wrapper input::placeholder {
  font-size: 25px; /* Adjust the font size as needed */

.date-input-container-site {
  display: flex;

  align-items: center;
}

.calendar-icon-site {
  position:relative;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #618e95;
  margin-top: 35px;
  margin-left: 20px;

}

.counter-site input[type="number"] {
  width: 40px; 
  height: 40px; 
  font-size: 20px;
}
.counter-site input[type="text"] {
  width: 18px; 
  height: 40px; 
  font-size: 15px; 
  text-align: center; 
  border: none; 
  outline: none; 
}


.site-card-popup {
  position: absolute;
  top: 280px;
  right: 70px;
  z-index: 999; 
}


.price-vat-txt {
  margin:auto;
  width: 85%;
}
.price-vat-txt p {
  background-color: #c7d9ea; 
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px; 
  font-weight: bold;
  color: #333; 
}
.vat-dropdown {
  position: relative;
  cursor: pointer;
}

.vat-dropdown i {
  position: absolute;
  margin-top: -15px;
  right: 30px; 
  transform: translateY(-50%) rotate(0deg);
  transition: transform 0.3s ease;
}

.vat-dropdown i.open {
  transform: translateY(-50%) rotate(180deg);
}

.vat-details {
  margin-top: 5px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.vat-list {
  font-weight: 400;

}

.price-site {
  float: right;
  font-weight: 400;
  margin-bottom: 5px;
}

.price-site1 {
  font-weight: 400;
  margin-right: 10px;
  float: right;
}