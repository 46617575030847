.facilities-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0px;
}

.facility-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100px; 
}

.facility-icon {
  width: 30px; 
  height: 30px;
}

.facility-title {
  margin-top: 8px;
  font-size: 13px; 
  color: #0b0b0b;
}


/* --------------------------*/

@media(max-width: 435px) {
  .facility-icon {
    width: 25px;
    height: 25px;
  }

  .facility-title {
    font-size: 5px;
  }
}

@media(max-width: 768px) {
  .facilitiescontainer1 {
    justify-content: center;
  }

  .facilityitem1 {
    margin: 5px;
    width: 70px; 
  }
}

@media only screen and (min-width: 1370px) and (max-width: 1440px) {
  .facility-icon {
    width: 35px;
    height: 35px;
  }

  
}