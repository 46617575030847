.container-rev {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    align-items: flex-start;
  }
  
  
  .categories-rev {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  .rev-home-head{
    font-family: 'Cinzel';
    font-size: 30PX;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .category-rev {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .category-home{
    font-family: 'Cinzel';
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .category-name-rev {
    font-family: 'lato';
    margin-bottom: -15px;
    display: flex;
    align-items: center;
  }
  .down-arrow-rev {
    margin-left: 5px;
    color: #618e95;
  }
  .category-score-rev {
    font-size: 14px;
    margin-bottom: 5px;
    margin-left: 350px;
  }
  
  .topics-rev {
    margin: 20px 0;
  }
  
  .topics-rev .ant-tag {
    margin-bottom: 10px;
  }
  
  .progress-container-rev {
    position: relative;
    height: 20px;
    margin-top: 15px;
  }
  .progress-container-rev {
    width: 50%; /* Adjust the width as needed */
  }
  .progress-container-rev progress {
    width: 100%;
  }
  .review-show-btn{
    padding: 10px 60px;
    background-color: #618e95;
    color: #ffffff;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    margin-top: 70px;
  }
  .review-show-btn:hover{
    background-color: black;
    color:white;
  }
  .review-show {
    margin-left: auto; 
    margin-top: -80px; 
  }
 .topic-buttons-container-rev {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjust this value as needed */
  flex-wrap: wrap;
}
 
 
  .guest-reviews-rev {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
  }
  .score-rev {
    background-color: #136f63;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .details-rev {
    margin-left: 10px;
  }
  
  .review-text-rev {
    font-weight: bold;
  }
  
  .read-reviews-link-rev {
    margin-left: 10px;
    color: #0057b8; /* Adjust color to match your design */
    text-decoration: none;
  }
  
  .read-reviews-link-rev:hover {
    text-decoration: underline;
  }
  
  .button-head-rev{
    font-size: 18px;
    font-family: 'leto';
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .topic-buttons-rev {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    
  }
  
  .topic-buttons-rev .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border: 1px solid;
    height: 40px;
    font-family: 'leto';
  }
  @media only screen and (min-width: 320px) and (max-width: 375px)
  {
    .container-rev {
      max-width: 400px;
      margin: 0 auto;
      padding: 10px;
      align-items: flex-start;
      margin-bottom: 20px;
    }
    .categories-rev {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .category-score-rev {
      margin-left: 280px;
    }
    .button-head-rev{
      margin-left: 5px;
    }
    .topic-buttons-rev {
      margin-left: 15px;
     }
     .review-show{
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
     
    }
   
  }
  @media only screen and (min-width: 376px) and (max-width: 435px)
  {
    .container-rev {
      max-width: 400px;
      margin: 0 auto;
      padding: 10px;
      align-items: flex-start;
      margin-bottom: 20px;
    }
    .categories-rev {
      display: grid;
      grid-template-columns: repeat(1, 0fr);
      gap: 20px;
    }
    .button-head-rev{
      margin-left: 5px;
    }
    .topic-buttons-rev {
      margin-left: 15px;
     }
     .review-show{
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
     
    }
    .category-score-rev {
      margin-left: 350px;
    }
   
  }
  @media only screen and (min-width: 440px) and (max-width: 820px)
  {
    .container-rev {
      margin: 0 auto;
      padding: 10px;
      align-items: flex-start;
      margin-bottom: 20px;
    }
    .categories-rev {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    .button-head-rev{
      margin-left: 5px;
    }
    .topic-buttons-rev {
      margin-left: 15px;
     }
     .review-show{
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 20px;
     
    }
    .category-score-rev {
      margin-left: 300px;
    }
   
  }
 
  @media only screen and (min-width: 770px) and (max-width: 1025px)
  {
    .container-rev {
      margin: 0 auto;
      padding: 10px;
      align-items: flex-start;
      margin-bottom: 20px;
    }
    .categories-rev {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    .button-head-rev{
      margin-left: 5px;
    }
    .topic-buttons-rev {
      margin-left: 15px;
     }
     .review-show{
      margin-right: 30px;
     
    }
    .category-score-rev {
      margin-left: 420px;
    }
   
  }
 
 
  
  
  
  
 
  
  
  
  